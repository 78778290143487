import { Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  media$: Observable<MediaChange[]>;

  constructor(
    public media: MediaObserver,
    private gtmService: GoogleTagManagerService,
    private router: Router
  ) {
    this.media$ = this.media.asObservable();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        const navEndEvent = event as NavigationEnd; 
        const gtmTag = {
          event: 'pageview',
          pageName: navEndEvent.urlAfterRedirects,
        };
        this.gtmService.pushTag(gtmTag);
      });
  }
}
